<form name="slide2" (ngSubmit)="f.valid && submit()" #f="ngForm">

    <div class="row">
        <div class="col-md-6">
            <button (click)="back()" type="button" class="btn btn-primary">Company Info</button>
        </div>
        <div class="col-md-6">
            <button [disabled]="f.invalid" type="submit" class="btn btn-primary float-right">Payment</button>
        </div>
    </div>
</form>
<!--<pre>-->
<!--    {{ f.value  | json}}-->
<!--</pre>-->
