import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyInterface } from '../interfaces/company.interface';

@Component({
    selector: 'app-slide2',
    templateUrl: './slide2.component.html',
})
export class Slide2Component {
    @Input() company: CompanyInterface;
    @Output() onBack: EventEmitter<void> = new EventEmitter();
    @Output() onSubmit: EventEmitter<void> = new EventEmitter();

    submit() {
        console.log('on submit');
        this.onSubmit.emit();
    }

    back() {
        this.onBack.emit();
    }
}
