import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyInterface } from '../interfaces/company.interface';

@Component({
    selector: 'app-slide1',
    templateUrl: './slide1.component.html',
})
export class Slide1Component {
    @Input() company: CompanyInterface;
    @Output() onSubmit: EventEmitter<void> = new EventEmitter();

    submit() {
        console.log('on submit');
        this.onSubmit.emit();
    }
}
