import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormComponent } from './form/form.component';
import { Slide1Component } from './form/slide1/slide1.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Slide2Component } from './form/slide2/slide2.component';

@NgModule({
    declarations: [FormComponent, Slide1Component, Slide2Component],
    imports: [BrowserModule, FormsModule, CommonModule],
    providers: [],
    bootstrap: [FormComponent],
})
export class AppModule {}
