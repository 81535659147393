import { Component } from '@angular/core';
import { CompanyInterface } from './interfaces/company.interface';

@Component({
    selector: 'app-signup-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.sass'],
})
export class FormComponent {
    slide: number = 1;
    company: CompanyInterface = {};
    constructor() {
        this.company = {};
    }

    backToSlide1() {
        this.slide = 1;
    }

    submitSlide1() {
        console.log(this.company);
        this.slide = 2;
    }

    submitSlide2() {
        console.log(this.company);
        this.slide = 3;
    }
}
