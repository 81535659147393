<form name="slide1" (ngSubmit)="f.valid && submit()" #f="ngForm">
    <div class="form-group">
        <label for="form_company_name">Company name*</label>
        <input [(ngModel)]="company.companyName" type="text" required class="form-control" id="form_company_name" name="company_name">
    </div>
    <div class="form-group">
        <label for="form_address">Address*</label>
        <input [(ngModel)]="company.address" type="text" required class="form-control" id="form_address" name="address">
    </div>
    <div class="row">
        <div class="col col-md-3 form-group">
            <label for="form_zip_code">Zip code (option)</label>
            <input [(ngModel)]="company.zipCode" type="text" class="form-control" id="form_zip_code" name="zip_code">
        </div>
        <div class="col form-group">
            <label for="form_city">City *</label>
            <input [(ngModel)]="company.city" type="text" required class="form-control" id="form_city" name="form_city">
        </div>
    </div>
    <div class="form-group">
        <label for="form_country">Country*</label>
        <input [(ngModel)]="company.country" type="text" required class="form-control" id="form_country" name="country">
    </div>
    <div class="form-group">
        <label for="form_vat">VAT number (optional)</label>
        <input [(ngModel)]="company.vat" type="text" class="form-control" id="form_vat" name="vat">
    </div>
    <div class="form-group form-check">
        <input type="checkbox" required class="form-check-input" id="form_agres" name="form_agree">
        <label class="form-check-label"  for="form_agree">I agree with the Privacy Policy, Terms & Conditions</label>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button [disabled]="f.invalid" type="submit" class="btn btn-primary float-right">Subscription Plan</button>
        </div>
    </div>
</form>
<pre>
    {{ f.value  | json}}
</pre>
